<template>
  <div class="rcc-notifications-form">
    <v-form
      ref="form"
      class="rcc-notifications-form__form"
      v-model="valid"
      @submit.prevent="onSubmitForm"
    >
      <section>
        <h2>Темы писем для email-оповещений</h2>
          <div class="rcc-notifications-form__form-grid">
            <div class="rcc-notifications-form__grid-column">
              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Завершение основного бронирования"
                v-model="form.name"
              />

              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Завершение работы парсера по"
                v-model="form.login"
              />

              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Новый РЦ на портале X5"
                v-model="form.login"
              />

              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Новая заявка конкурента или presale"
                v-model="form.login"
              />
            </div>

            <div class="rcc-notifications-form__grid-column">
              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Изменение заявки конкурентом"
                v-model="form.login"
              />

              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Ошибка аутентификации робота"
                v-model="form.login"
              />

              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Прерванный процесс бронирования"
                v-model="form.login"
              />

              <rcc-text-input
                class="rcc-notifications-form__input"
                label="Автоматический ежедневный отчет"
                v-model="form.login"
              />
            </div>
        </div>
      </section>

      <section>
        <h2>Время отправки ежедневного отчета</h2>
        <rcc-time-input label="Высылать ежедневный отчет в" width="400px" />
      </section>
    </v-form>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ path: '/', name: 'returns' })" />
  </div>
</template>

<script>
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccTimeInput from 'Components/controls/rcc-time-input'
import RccTextInput from 'Components/controls/rcc-text-input'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccTimeInput
  },

  mixins: [Page, Form],

  data() {
    return {
      rolesOptions: [],
      form: {
        name: '',
        login: '',
        role: 0,
        email: ''
      }
    }
  },

  methods: {
    onSubmit() {
      // this.createDetailing(this.form)
      //   .then(() => {
      //     this.successSubmit({ message: 'Детализация успешно создана', routeName: 'detailing' })
      //   })
      //   .finally(() => { this.afterSubmit() })
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-notifications-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__form-grid {
    display: flex;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }

  &__grid-column {
    width: 40%;

    @media screen and (max-width: 1600px) {
      width: 50%;
    }

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  &__input {
    width: 70%;

    @media screen and (max-width: 1400px) {
      width: 90%;
    }

    @media screen and (max-width: 900px) {
      width: 70%;
    }

    @media screen and (max-width: 700px) {
      width: 90%;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}
</style>
