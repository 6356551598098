import getBreadcrumbs from '@/utils/get-breadcrumbs'

export default {
  data() {
    return {
      breadcrumbs: []
    }
  },

  created() {
    this.setBreadcrumbs()
  },

  methods: {
    setBreadcrumbs() {
      this.$store.breadcrumbs = getBreadcrumbs(this.$route.name)
    }
  }
}
