<template>
  <v-btn
    :class="buttonClasses"
    :rounded="isRounded && !isIcon"
    :outlined="isOutlined"
    :depressed="isDepressed"
    :large="size === 'large'"
    :x-small="size === 'x-small'"
    :color="color"
    :icon="isIcon"
    :type="type"
    :disabled="isDisabled"
    :loading="isLoading"
    @click="$emit('click')"
    @submit="$emit('submit')"
  >
    <slot />
  </v-btn>
</template>

<script>
const COLORS = ['primary', 'warning', 'success', 'error']
const SIZES = ['x-small', 'large', 'normal']

export default {
  name: 'RccButton',

  props: {
    isRounded: {
      type: Boolean,
      default: true
    },

    isOutlined: {
      type: Boolean,
      default: false
    },

    isDepressed: {
      type: Boolean,
      default: true
    },

    isIcon: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'primary'
    },

    size: {
      type: String,
      validator: value => {
        return SIZES.includes(value)
      },
      default: 'normal'
    },

    type: {
      type: String,
      validator: value => {
        return ['button', 'submit'].includes(value)
      },
      default: 'button'
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClasses() {
      const baseClass = 'v-btn'
      return [
        this.color === 'primary' && baseClass + '_primary',
        this.color === 'warning' && baseClass +'_warning',
        this.color === 'success' && baseClass + '_success',
        this.color === 'error' && baseClass + '_error',
        this.size === 'x-small' && baseClass + '_x-small',
        this.size === 'normal' && baseClass + '_normal',
        this.isIcon && baseClass + '_icon'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@function shadow($color) {
  @return 0px 8px 9px -2px transparentize($color, .7)
}

.v-btn {
  transition: transform .2s ease;
  position: relative;
  font-size: 11px;

  &:hover {
    transform: translateY(-2px);

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      bottom: -2px;
    }
  }

  &_primary {
    &:hover {
      box-shadow: shadow($primary)
    }
  }

  &_warning {
    &:hover {
      box-shadow: shadow($warning)
    }
  }

  &_success {
    &:hover {
      box-shadow: shadow($success)
    }
  }

  &_error {
    &:hover {
      box-shadow: shadow($error)
    }
  }

  &_normal {
    height: 30px !important;
  }

  &_x-small {
    &:hover {
      transform: translateY(-1px);

      &::after {
        bottom: -1px;
      }
    }
  }

  &_icon {
    &:hover {
      box-shadow: none;
      transform: unset;
      background: none;
      transform: rotate(360deg);

      &::after {
        bottom: unset;
      }
    }
  }
}
</style>
